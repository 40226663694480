import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import "./layout.css"

import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
import { FaGithub } from "@react-icons/all-files/fa/FaGithub"
import { IconContext } from "@react-icons/all-files"

const Header = () => {
  return (
    <ol>
      <li>
        <Link to="/">All articles</Link>
      </li>
      <li>
        <a href="https://createapp.dev/">createapp.dev</a>
      </li>
      <li>
        <Link to="/about">About</Link>
      </li>
    </ol>
  )
}

class Layout extends React.Component {
  render() {
    const { location, title, children, productPage } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
            textAlign: "center",
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            marginTop: 0,
            marginBottom: rhythm(1),
            textAlign: "center",
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }

    const top = productPage ? null : (
      <>
        <header>{header}</header>
        <nav>
          <Header />
        </nav>
      </>
    )

    const bottom = productPage ? null : (
      <footer>
        <br />
        <br />
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-around`,
            listStyle: `none`,
            color: "black",
          }}
        >
          <li>
            <a href="https://twitter.com/karljakoblind">
              <IconContext.Provider
                value={{ style: { marginRight: "7px", paddingTop: "3px" } }}
              >
                <FaTwitter />
              </IconContext.Provider>
              Twitter
            </a>
          </li>
          <li>
            <a href="https://github.com/jakoblind">
              <IconContext.Provider
                value={{ style: { marginRight: "7px", paddingTop: "3px" } }}
              >
                <FaGithub />
              </IconContext.Provider>{" "}
              Github
            </a>
          </li>
        </ul>
      </footer>
    )

    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        {top}
        <main>{children}</main>
        {bottom}
      </div>
    )
  }
}

export default Layout
